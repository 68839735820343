var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-container step-one"},[_c('q-form',{staticClass:"q-gutter-md",on:{"submit":_vm.onSubmit}},[(!_vm.isEdit)?_c('div',{staticClass:"form-header"},[_c('div',[_vm._v("Créer un nouveau projet")]),_c('p',[_vm._v(" Choisir un nom, une description et précisez quels départements ou avocats sont concernés par ce nouveau projet ")])]):_vm._e(),_c('q-input',{attrs:{"counter":"","maxlength":_vm.maxLengthName,"square":"","outlined":"","bottom-slots":"","placeholder":"Nommez le projet","type":"text","lazy-rules":"","rules":[
        val =>
          (val && val.trim().length > 0) || 'Veuillez choisir le nom du projet'
      ]},nativeOn:{"input":function($event){return (e => _vm.checkName(e)).apply(null, arguments)}},scopedSlots:_vm._u([{key:"hint",fn:function(){return [_vm._v(" Nom du projet ")]},proxy:true}]),model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('q-input',{attrs:{"counter":"","maxlength":_vm.maxLengthDescription,"square":"","outlined":"","bottom-slots":"","placeholder":"Décrivez brièvement le projet","type":"text","lazy-rules":"","rules":[
        val =>
          (val && val.trim().length > 0) ||
          'Veuillez décrire le projet'
      ]},nativeOn:{"input":function($event){return (e => _vm.checkDescription(e)).apply(null, arguments)}},scopedSlots:_vm._u([{key:"hint",fn:function(){return [_vm._v(" Description du projet ")]},proxy:true}]),model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('q-select',{attrs:{"filled":"","use-input":"","use-chips":"","multiple":"","hide-dropdown-icon":"","input-debounce":"0","options":_vm.optionsDepartments,"hint":"Départements concernés","placeholder":_vm.form.departments && _vm.form.departments.length
          ? ''
          : `Commencez à écrire le nom d’un département`},on:{"filter":_vm.filterDepartments},model:{value:(_vm.form.departments),callback:function ($$v) {_vm.$set(_vm.form, "departments", $$v)},expression:"form.departments"}}),_c('q-select',{attrs:{"filled":"","use-input":"","use-chips":"","multiple":"","hide-dropdown-icon":"","input-debounce":"0","options":_vm.optionsLawyers,"hint":"Avocats concernés","placeholder":_vm.form.lawyers && _vm.form.lawyers.length
          ? ''
          : `Commencez à écrire le nom d’un avocat`},on:{"filter":_vm.filterLawyers},model:{value:(_vm.form.lawyers),callback:function ($$v) {_vm.$set(_vm.form, "lawyers", $$v)},expression:"form.lawyers"}}),(!_vm.isEdit)?_c('div',{staticClass:"bottom-buttons"},[_c('q-btn',{staticClass:"btn-cancel",attrs:{"label":"Annuler","type":"button"},on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('q-btn',{class:{ disabled: !_vm.canSubmitting || _vm.getIsSendingNewProject },attrs:{"loading":_vm.loading,"label":"Suivant","type":"submit"}})],1):_vm._e(),(_vm.isEdit)?_c('div',{staticClass:"bottom-buttons"},[(_vm.getCurrentUser.userRights.rightProjectDelete)?_c('q-btn',{staticClass:"btn-trash",attrs:{"label":"Supprimer le projet"},on:{"click":_vm.remove}},[_c('q-icon',{staticClass:"trash-icon"})],1):_vm._e(),_c('q-btn',{staticClass:"btn-cancel",attrs:{"label":"Annuler","type":"button"},on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('q-btn',{staticClass:"btn-save",attrs:{"loading":_vm.loading,"label":"Sauvegarder","type":"button"},on:{"click":_vm.save}},[_c('q-icon',{staticClass:"save-icon"})],1)],1):_vm._e()],1),_c('DeleteModal',{attrs:{"content":_vm.contentDeleteProject,"is-show":_vm.isDelete},on:{"update:isShow":function($event){_vm.isDelete=$event},"update:is-show":function($event){_vm.isDelete=$event},"closeModal":_vm.closeDeleteModal,"removed":_vm.removed}}),_c('SaveModal',{attrs:{"content":_vm.contentSave,"is-show":_vm.isSave},on:{"update:content":function($event){_vm.contentSave=$event},"update:isShow":function($event){_vm.isSave=$event},"update:is-show":function($event){_vm.isSave=$event},"closeModal":_vm.closeSaveModal,"saved":_vm.saved}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }