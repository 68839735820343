<template>
  <div class="form-container step-one">
    <q-form @submit="onSubmit" class="q-gutter-md">
      <div class="form-header" v-if="!isEdit">
        <div>Créer un nouveau projet</div>
        <p>
          Choisir un nom, une description et précisez quels départements ou avocats sont concernés par ce nouveau projet
        </p>
      </div>
      <q-input
        counter
        :maxlength="maxLengthName"
        square
        outlined
        bottom-slots
        v-model="form.name"
        placeholder="Nommez le projet"
        type="text"
        @input.native="e => checkName(e)"
        lazy-rules
        :rules="[
          val =>
            (val && val.trim().length > 0) || 'Veuillez choisir le nom du projet'
        ]"
      >
        <template v-slot:hint>
          Nom du projet
        </template>
      </q-input>

      <q-input
        counter
        :maxlength="maxLengthDescription"
        square
        outlined
        bottom-slots
        v-model="form.description"
        placeholder="Décrivez brièvement le projet"
        type="text"
        @input.native="e => checkDescription(e)"
        lazy-rules
        :rules="[
          val =>
            (val && val.trim().length > 0) ||
            'Veuillez décrire le projet'
        ]"
      >
        <template v-slot:hint>
          Description du projet
        </template>
      </q-input>

      <q-select
        filled
        v-model="form.departments"
        use-input
        use-chips
        multiple
        hide-dropdown-icon
        input-debounce="0"
        :options="optionsDepartments"
        @filter="filterDepartments"
        hint="Départements concernés"
        :placeholder="
          form.departments && form.departments.length
            ? ''
            : `Commencez à écrire le nom d’un département`
        "
      />

      <q-select
        filled
        v-model="form.lawyers"
        use-input
        use-chips
        multiple
        hide-dropdown-icon
        input-debounce="0"
        :options="optionsLawyers"
        @filter="filterLawyers"
        hint="Avocats concernés"
        :placeholder="
          form.lawyers && form.lawyers.length
            ? ''
            : `Commencez à écrire le nom d’un avocat`
        "
      />

      <div v-if="!isEdit" class="bottom-buttons">
        <q-btn
          label="Annuler"
          class="btn-cancel"
          type="button"
          @click="$router.go(-1)"
        />
        <q-btn
          :loading="loading"
          label="Suivant"
          type="submit"
          :class="{ disabled: !canSubmitting || getIsSendingNewProject }"
        />
      </div>
      <div v-if="isEdit" class="bottom-buttons">
        <q-btn
          v-if="getCurrentUser.userRights.rightProjectDelete"
          label="Supprimer le projet"
          class="btn-trash"
          @click="remove"
        >
          <q-icon class="trash-icon" />
        </q-btn>

        <q-btn
          label="Annuler"
          class="btn-cancel"
          type="button"
          @click="$router.go(-1)"
        />

        <q-btn
          :loading="loading"
          label="Sauvegarder"
          class="btn-save"
          type="button"
          @click="save"
        >
          <q-icon class="save-icon" />
        </q-btn>
      </div>
    </q-form>

    <DeleteModal
      :content="contentDeleteProject"
      :is-show.sync="isDelete"
      @closeModal="closeDeleteModal"
      @removed="removed"
    />

    <SaveModal
      :content.sync="contentSave"
      :is-show.sync="isSave"
      @closeModal="closeSaveModal"
      @saved="saved"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DeleteModal from '@/components/modals/Delete'
import SaveModal from '@/components/modals/Save'

export default {
  components: {
    SaveModal,
    DeleteModal
  },
  props: {
    content: {
      type: Object,
      default: () => {}
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      maxLengthName: 35,
      maxLengthDescription: 50,
      optionsDepartments: [],
      optionsLawyers: [],
      canSubmitting: false,
      form: {
        name: '',
        description: '',
        departments: null,
        lawyers: null
      },
      isDelete: false,
      contentDeleteProject: {
        title: 'Supprimer le projet',
        text: [
          'Êtes-vous sûr de vouloir supprimer ce projet ? ',
          'Vous ne pourrez pas annuler cette action.'
        ]
      },
      contentSave: {
        title: 'Sauvegarder les modification',
        text: ['Voulez-vous sauvegarder ces modifications ?'],
        id: '',
        saveUrl: ''
      },
      isSave: false
    }
  },
  async created() {
    await this.fetchDepartments()
    this.optionsDepartments = this.getDepartments
    await this.fetchLawyers()
    this.optionsLawyers = this.getLawyers

    if (this.isEdit) {
      const { projectId } = this.$route.params
      await this.fetchProject(projectId)

      const departments = this.getDepartments.filter(item =>
        this.getProject.departmentsId.includes(item.departmentId)
      )
      const lawyers = this.getLawyers.filter(item =>
        this.getProject.workingUsersId.includes(item.userId)
      )

      Vue.set(this.form, 'name', this.getProject.projectName)
      Vue.set(this.form, 'description', this.getProject.projectDescription)
      Vue.set(this.form, 'departments', departments)
      Vue.set(this.form, 'lawyers', lawyers)
    }

    const body = document.querySelector('body')
    body.classList.add('new-project-body')
  },
  computed: {
    ...mapGetters('projects', [
      'getDepartments',
      'getLawyers',
      'getIsSendingNewProject',
      'getErrorSendingNewProject',
      'getRemovingProject',
      'getErrorRemovingProject',
      'getProject',
      'getLoadingProject',
      'getErrorProject',
      'getPutProject',
      'getErrorPutProject'
    ]),
    ...mapGetters('users', ['getCurrentUser'])
  },
  methods: {
    ...mapActions('projects', [
      'fetchDepartments',
      'fetchLawyers',
      'sendNewProject',
      'removeProject',
      'fetchProject',
      'putProject',
      'setProjectName'
    ]),
    async onSubmit() {
      this.loading = true
      const { name, description, departments, lawyers } = this.form

      const data = {
        projectName: name.trim(),
        projectDescription: description.trim(),
        departmentsId:
          departments && departments.length
            ? departments.map(item => item.departmentId)
            : [],
        workingUsersId:
          lawyers && lawyers.length ? lawyers.map(item => item.userId) : []
      }
      
      this.setProjectName(name)

      if (this.isEdit) {
        const { projectId } = this.$route.params
        await this.putProject({ projectId, data })

        if (this.getErrorPutProject.length) {
          this.showNotify(this.getErrorPutProject)
          this.loading = false
          return
        }

        this.$router.push(`/project/${projectId}`)
      } else {
        const projectId = await this.sendNewProject(data)

        if (projectId) {
          await this.$router.push(`/new-project/?projectId=${projectId}`)
          this.$emit('setStep', 2)
        }

        if (this.getErrorSendingNewProject.length) {
          this.showNotify(this.getErrorSendingNewProject)
          this.loading = false
        }
      }
    },
    filterDepartments(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase()
        this.optionsDepartments = this.getDepartments.filter(
          v => v.label.toLocaleLowerCase().indexOf(needle) > -1
        )
      })
    },
    filterLawyers(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase()
        this.optionsLawyers = this.getLawyers.filter(
          v => v.label.toLocaleLowerCase().indexOf(needle) > -1
        )
      })
    },
    closeDeleteModal() {
      this.isDelete = false
    },
    remove() {
      this.isDelete = true
    },
    async removed() {
      const { projectId } = this.$route.params
      await this.removeProject({ projectId })

      if (this.getErrorRemovingProject.length) {
        this.showNotify(this.getErrorRemovingProject)
        return
      }
      this.isDelete = false
      this.$router.push('/projects')
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    closeSaveModal() {
      this.isSave = false
    },
    save() {
      this.isSave = true
    },
    saved() {
      this.isSave = false
      this.onSubmit()
    },
    checkName() {
      this.form.name = this.form.name.trimStart()
    },
    checkDescription() {
      this.form.description = this.form.description.trimStart()
    }
  },
  watch: {
    form: {
      handler() {
        const { name, description } = this.form
        this.canSubmitting = name.length && description.length
      },
      deep: true
    },
    getErrorProject: function(message) {
      if (message && message.length) this.showNotify(message)
    }
  },
  beforeDestroy() {
    const body = document.querySelector('body')
    body.classList.remove('new-project-body')
  }
}
</script>
