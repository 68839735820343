<template>
  <div class="new-project edit-project">
    <q-page-container>
      <q-page>
        <StepOne :is-edit="true" />
      </q-page>
    </q-page-container>
  </div>
</template>

<script>
import StepOne from '@/components/new-project/StepOne'

export default {
  components: {
    StepOne
  }
}
</script>
